import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
//import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CodeIcon from "@material-ui/icons/Code";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Projects = () => {
  const [items] = useState([
    {
      cardTitle: "Data Analysis",
      cardDetailedText: [
        "Completed Data Analysis projects with Simple linear regression, Multi linear regression, one-way ANOVA, two-way ANOVA.",
        "Technology used: R studio Cloud.",
      ],
      technologies: ["Linear regression", "Multi linear regression", "ANOVA"],
      links: [],
    },
    {
      cardTitle: "Cloud Infrastructure",
      cardDetailedText: [
        "Created 3 different environments Dev, QA, and Prod. Goal was to create ECS (EC2) in dev environment, stage and production env ECS (Fargate) with Terraform.",
        "Created resources as follows: VPC with public private subnets, Route Tables attached to subnets, Internet Gateway attached to public subnets, security groups and roles for ECS Cluster and Task definitions and services, created two LB using Blue/Green deployment and CI/CD using AWS Code Pipeline for production.",
        "AWS Service used: ECS, AWS Code Pipeline, Code Build, Code Deployment, RDS.",
        "Toxic Comment Detection Analysis.",
        "Created multi-language toxic comment detection analysis using machine learning algorithms.",
        "Used four different machine learning models to evaluate Google’s Jigsaw Multi-lingual toxic comment classification dataset.",
        "Technology Used: Python, Machine Learning Algorithms.",
      ],
      technologies: ["Python", "ECS (EC2)", "VPC", "AWS Code Pipeline", "RDS", "Machine Learning Algorithms"],
      links: [],
    },
  ]);

  const [limit, setLimit] = useState(3);
  const [loadButton, setLoadButton] = useState(true);
  const [lessButton, setLessButton] = useState(false);

  const loadMore = () => {
    setLimit(items.length); // Assuming you might have more items
    setLoadButton(false);
    setLessButton(true);
  };

  const loadLess = () => {
    setLimit(3);
    setLoadButton(true);
    setLessButton(false);
  };

  return (
    <section id="projects" className="about background-alt">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Projects</h2>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <VerticalTimeline className="custom-line">
            {items.slice(0, limit).map((data, i) => (
  <VerticalTimelineElement
    key={i}
    className="vertical-timeline-element--work"
    contentStyle={{ background: "#343a40" }}
    dateClassName="timeline-date"
    contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
    icon={<CodeIcon />}
    iconStyle={{ background: "#0563bb", color: "#fff" }}
  >
    <div className="projects-technologies">
      {data.technologies.map((name, techIndex) => (
        <Chip key={techIndex} label={name} />
      ))}
    </div>
    <h3
      className="vertical-timeline-element-title"
      style={{ fontSize: "1.25rem", color: "rgba(255, 255, 255, 0.8)" }}
    >
      {data.cardTitle}
    </h3>
    <ul style={{ color: "#74808a" }}>
      {data.cardDetailedText && data.cardDetailedText.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
    <div className="project-links">
      {data.links.map((link, j) => (
        <Button
          key={j}
          variant="contained"
          color="default"
          target="_blank"
          href={link.url}
        >
          {link.text}
        </Button>
      ))}
    </div>
  </VerticalTimelineElement>
))}
              {loadButton && (
                <IconButton aria-label="add" onClick={loadMore} style={{ backgroundColor: "#0563bb", color: "#fff", margin: "0 auto", display: "flex" }}>
                  <AddIcon />
                </IconButton>
              )}
              {lessButton && (
                <IconButton aria-label="remove" onClick={loadLess} style={{ backgroundColor: "#0563bb", color: "#fff", margin: "0 auto", display: "flex" }}>
                  <RemoveIcon />
                </IconButton>
              )}
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
