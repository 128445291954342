import React from 'react';
const Skills = () => {

  const skills = [
    "Bash", "Shell", "Python", "R", "GO",
    "JavaScript", "JSON", "YAML", "AWS", "Microsoft Azure",
    "GCP", "Heroku", "Vercel", "Cloudflare", "GIT",
    "GitHub", "GITLAB", "BitBucket", "Docker", "Kubernetes/K8",
    "Jenkins", "Azure DevOps", "Ansible", "Chef", "Puppet"
  ]
  return (
    <section id="skills" class="skills section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Skills</h2>
        </div>
        <div>
          <ul>
              {
                skills.map((skill, i) => (
                  <li key={i}>{skill}</li>
                ))
              }
          </ul>
        </div>

      </div>
    </section>
  );
}

export default Skills;