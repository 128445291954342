import img from "./images/1.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from 'react';

export default function About() {
  return (
    <section id="about" class="about background-alt">
      <Container data-aos="fade-up">
        <div class="section-title">
          <h2>About</h2>
        </div>

        <Row>
          <Col lg={4} className="d-flex justify-content-center">
            <img
              src={img}
              height="100%"
              width="69%"
              style={{ borderRadius: "50%" }}
              alt="1"
            />
          </Col>
          <Col lg={8} pt={4} className="content">
            <br></br>
            <h3>Devops Engineer</h3>
            <br></br>
            <p style={{ color: "#728394" }}>
              Welcome to my digital space! I am a dedicated DevOps Engineer with a passion for bridging the gap between software development and operations through innovative cloud solutions, automation, and effective system integration. My journey is fueled by a relentless pursuit of efficiency, scalability, and reliability in every project I embark on. Beyond orchestrating seamless deployment pipelines and infrastructure as code, I thrive on continuous learning and adapting to the ever-evolving tech landscape.
            </p>
            <br></br>
            <p style={{ color: "#728394" }}>
              In pursuit of challenging roles within dynamic organizations, I am eager to leverage my expertise in DevOps practices to foster environments where continuous integration, continuous delivery, and high operational performance are not just goals, but standards. My mission is to drive growth, efficiency, and resilience through technology, ensuring my skills contribute significantly to the company's success and innovation. I am always on the lookout for opportunities to expand my knowledge, push boundaries, and contribute to a culture of excellence and continuous improvement.
            </p>
            <br></br>
            <Row>
              <Col lg={4}>
                <ul>
                  <li>
                    <i class="bi bi-chevron-right"></i> <strong>Phone:</strong>{" "}
                    <span>+1 9517432535</span>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i> <strong>City:</strong>{" "}
                    <span>Jersey City, New Jersey</span>
                  </li>
                </ul>
              </Col>
              <Col lg={7}>
                <ul>
                  <li>
                    <i class="bi bi-chevron-right"></i> <strong>Degree:</strong>{" "}
                    <span>Master of Science in Software Engineering (2019-2021)</span>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i> <strong>Email:</strong>{" "}
                    <span>
                      <a href="mailto:hinahirpara4897@gmail.com">
                        hinahirpara4897@gmail.com
                      </a>
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
