import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@material-ui/icons/Work";
import React from 'react';
export default function Experience() {
  const items = [
    {
      date: "August 2021 - Present",
      cardTitle: "Arria NLG",
      cardSubtitle: "DevOps Engineer",
      cardDetailedText: [
        "Enhanced the CI/CD pipeline efficiency and monitoring capabilities by optimizing build times by 30% and deploying Grafana, Alert manager, and Prometheus for improved issue detection. Led the shift towards containerization using Docker & Kubernetes within Azure Active Directory for better scalability and maintenance.",
        "Advanced network automation and security across AWS and Azure platforms, utilizing Agile and DevSecOps practices to boost productivity and reduce risks. Implemented high-availability strategies and integrated AQUASEC into CI/CD pipelines, managing critical incidents and releases as part of the on-call team.",
      ].map(function (text) {
        return <li>{text}</li>;
      }),
    },
    {
      date: "September 2016 – February 2018",
      cardTitle: "GTPL Hathway Ltd",
      cardSubtitle: "Jr. Cloud/DevOps Engineer",
      cardDetailedText: [
        "Collected JSON data by web-scraping, using open API end points and storing it in a RDBMS.",
        "Responsible for building SKU Classification system using the collected data.",
      ].map(function (text) {
        return <li>{text}</li>;
      }),
    },
    {
      date: "April 2016 – May 2016",
      cardTitle: "Kasseva Health & Wellness",
      cardSubtitle: "Digital Marketing Intern",
      cardDetailedText: [
        "Developed applications using Spring Framework for Backend Restful API’s.",
        "Wrote SQL/JPQL queries for Data persistence using Spring Data JPA.",
      ].map(function (text) {
        return <li>{text}</li>;
      }),
    },
  ];

  return (
    <section id="experience" class="about background-alt">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Experience</h2>
        </div>
        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center">
            <VerticalTimeline className="custom-line">
              {items.map((data, i) => {
                return (
                  <VerticalTimelineElement
                    key={i}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: "#343a40" }}
                    date={data.date}
                    dateClassName="timeline-date"
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(33, 150, 243)",
                    }}
                    iconStyle={{
                      background: "#0563bb",
                      color: "#fff",
                    }}
                    icon={<WorkIcon />}
                  >
                    <h3
                      className="vertical-timeline-element-title"
                      style={{
                        fontSize: "14",
                        color: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      {data.cardTitle}
                    </h3>
                    <h4
                      className="vertical-timeline-element-subtitle"
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "350",
                        color: "#7e8890",
                      }}
                    >
                      {data.cardSubtitle}
                    </h4>
                    <p
                      style={{
                        fontSize: "1em",
                        fontWeight: "350",
                        color: "#74808a",
                      }}
                    >
                      {data.cardDetailedText}
                    </p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </section>
  );
}
